<template>

  <div class="pb">
    <h1>Experimental</h1>

    {{ useClientIsBot().value ? 'Bot' : 'User' }}
    {{ useClientIsMobile().value ? 'Mobile' : 'Desktop' }}
    <br>

    <h2>Ui </h2>

    <div class="tg">
      <UiToggle 
        @update:modelValue="switchExperimentsDiaryWide"
        :label="'Show diaries v2'"
        v-model="experimentsDiaryWide" 
        />
    </div>

    <div class="tg">
      <UiToggle 
        @update:modelValue="switchTranslateLinks"
        :label="'Show translate links'"
        v-model="translateLinks" 
        />
    </div>

    <div class="tg">
      <UiToggle 
        @update:modelValue="switchDisabledAdv"
        :label="'Disable Adv'"
        v-model="disabledAdv" 
        />
    </div>

    <br>

    <h2>Adv</h2>

    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='auto'" value="auto"> Auto</label>
    </div>
    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='es'" value="es"> Spain</label>
    </div>
    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='us'" value="us"> USA</label>
    </div>
    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='th'" value="th"> Thai</label>
    </div>


  </div>

</template>

<script setup lang="ts">
 
const experimentsDiaryWide = ref(useExperimentsDiaryWide().value ? 0 : 1)
const translateLinks = ref(useTranslateLinks().value ? 1 : 0)
const disabledAdv = ref(useDisabledAdv().value ? 1 : 0)


const switchExperimentsDiaryWide = (val) => {
  console.log('val:')
  console.log(val)
  experimentsDiaryWide.value = val
  useExperimentsDiaryWide().value = !val ? true : false
}

const switchTranslateLinks = (val) => {
  console.log('val:')
  console.log(val)
  translateLinks.value = val
  useTranslateLinks().value = val ? true : false
}

const switchDisabledAdv = (val) => {
  console.log('val:')
  console.log(val)
  disabledAdv.value = val
  useDisabledAdv().value = val ? true : false
}

</script>

<style scoped>

.text-style-info{
    text-indent: 2rem;
}

.html_into :v-deep(.de_im){
  height: 14px;
  vertical-align: middle;
}

.tg{
  margin-top: 10px;
}
 
 
</style>